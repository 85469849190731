import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import { validEmail } from '@propertypal/shared/src/utils/regex';
import { useFormik } from 'formik';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import Button from '../buttons/Button';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import SelectInput, { Option } from '../inputs/SelectInput';
import TextInput from '../inputs/TextInput';
import { Box } from '../layout';
import WhiteModal from '../modal/WhiteModal';
import { SubHeading, Text } from '../typography';
import { ErrorText, Form, InputsContainer } from './ContentHubNewsLetterModal.style';

interface Props {
  show: boolean;
  onClose: () => void;
  email: string;
  location: string;
  extraParams?: Record<string, any>;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(validEmail, 'Please enter a valid email address')
    .required('Please enter your email address'),
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  description: yup.string().required('Please select how you would describe yourself.'),
});

type Group = { title: string; options: Option[] };

const ContentHubNewsLetterModal: FunctionComponent<Props> = ({ show, onClose, email, location, extraParams }) => {
  const [sending, setSending] = useState(false);
  const [result, setResult] = useState<'success' | 'error' | 'alreadyExists' | 'failed' | null>(null);
  const [groups, setGroups] = useState<Group[] | null>(null);

  const closeRef = useRef<HTMLButtonElement>(null);

  const getGroups = async (): Promise<Group[] | null> => {
    const response = await fetch('/api/mailchimp/groups', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const data: Group[] | null = await response.json();

      return data;
    }
    return null;
  };

  const formik = useFormik({
    initialValues: {
      email,
      firstName: '',
      lastName: '',
      description: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setSending(true);

      const response = await fetch('/api/mailchimp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          interests: {
            [values.description]: true,
          },
        }),
      });

      if (response.ok) {
        trackGaEvent('subscribe_confirm', {
          page: 'Signup modal',
          location,
          ...(extraParams || {}),
        });
        setResult('success');
      } else {
        try {
          const err = await response.json();
          setResult(err?.alreadyExists ? 'alreadyExists' : 'error');
        } catch {
          setResult('error');
        }
      }

      setSending(false);
    },
  });

  useEffect(() => {
    if (show) {
      closeRef.current?.focus();
      // fetch groups and options to pass as interests property to POST body
      getGroups().then((g) => {
        if (!g?.length) setResult('failed');
        else setGroups(g);
      });
    }
  }, [show]);

  return (
    <WhiteModal show={show} onClose={onClose} title="Subscribe to our newsletter" closeRef={closeRef} maxWidth={560}>
      {result !== 'success' && result !== 'failed' && (
        <>
          <Text align="center" fontWeight="bold">
            To make sure you get the most relevant updates, please fill in the details below.
          </Text>
          <Form onSubmit={formik.handleSubmit}>
            <InputsContainer>
              <TextInput
                placeholder="Email address"
                name="email"
                value={formik.values.email}
                onValueChange={formik.handleChange('email')}
                error={formik.touched.email && formik.errors.email}
                testID="news-letter-email-input"
                label="Personal details"
              />
              <TextInput
                placeholder="First Name"
                name="firstName"
                value={formik.values.firstName}
                onValueChange={formik.handleChange('firstName')}
                error={formik.touched.firstName && formik.errors.firstName}
                testID="news-letter-firstName-input"
              />
              <TextInput
                placeholder="Last Name"
                name="lastName"
                value={formik.values.lastName}
                onValueChange={formik.handleChange('lastName')}
                error={formik.touched.lastName && formik.errors.lastName}
                testID="news-letter-lastName-input"
              />
            </InputsContainer>
            {!!groups?.length && (
              <SelectInput
                label={groups[0].title}
                ariaLabel={groups[0].title}
                placeholder="Select one option"
                id="description"
                options={groups[0].options}
                value={formik.values.description}
                onChange={formik.handleChange('description')}
                error={formik.touched.description && formik.errors.description}
                testID="news-letter-description-select"
              />
            )}
            <Button
              loading={sending || !groups}
              disabled={sending || !groups}
              type="submit"
              containerStyle={{ marginTop: 30 }}
              testID="news-letter-submit"
            >
              Subscribe to our newsletter
            </Button>
          </Form>
          {result === 'error' && (
            <ErrorText data-testid="news-letter-error">
              There was an error trying to sign you up. Please try another email or try again later.
            </ErrorText>
          )}
          {result === 'alreadyExists' && (
            <ErrorText data-testid="news-letter-already-exists" primary>
              Oops! This email has already been subscribed to our Newsletter
            </ErrorText>
          )}
        </>
      )}
      {result === 'failed' && (
        <ErrorText data-testid="news-letter-failed" style={{ marginTop: 0 }}>
          We&apos;re having problems generating subscription form, please contact help@propertypal.com.
        </ErrorText>
      )}
      {result === 'success' && (
        <Box
          data-testid="news-letter-sent"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          m="30px auto"
        >
          <FontAwesomeIcon icon={faPaperPlane} style={{ fontSize: 46 }} />
          <SubHeading mt={20} fontSize={20} align="center" fontWeight="600">
            Thanks for subscribing to our newsletter!
          </SubHeading>
        </Box>
      )}
    </WhiteModal>
  );
};

export default ContentHubNewsLetterModal;
