import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import React, { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Heading } from '../typography';
import ModalWrapper from './ModalWrapper';
import { Content, Container, CloseButton, Header, Body } from './WhiteModal.style';

interface Props {
  ariaLabel?: string;
  show: boolean;
  onClose: () => void;
  closeRef?: React.RefObject<HTMLButtonElement>;
  title?: string;
  maxWidth?: number;
  containerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  children: ReactNode;
  footer?: JSX.Element;
  header?: JSX.Element;
  className?: string;
  collapseContent?: boolean;
}

const WhiteModal: FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <ModalWrapper ariaLabel={props.ariaLabel} show={props.show} onClose={props.onClose}>
      <Container style={props.containerStyle} className={props.className}>
        {props.header}

        <Content maxWidth={props.maxWidth} style={props.contentStyle}>
          <Header $border={!!props.title}>
            {props.title && (
              <Heading data-testid="title" align="center" fontSize={24}>
                {props.title}
              </Heading>
            )}

            <CloseButton
              ref={props.closeRef}
              onClick={props.onClose}
              aria-label="Close enquiry modal"
              data-testid="closeModalButton"
            >
              <FontAwesomeIcon icon={faTimes} color={theme.backgroundDark} style={{ fontSize: 32, margin: 10 }} />
            </CloseButton>
          </Header>

          <Body $collapseContent={props.collapseContent}>{props.children}</Body>
        </Content>

        {props.footer}
      </Container>
    </ModalWrapper>
  );
};

export default WhiteModal;
