import styled from 'styled-components';
import Button from '../buttons/Button';
import { OptionList } from '../inputs/SelectInput.style';

export const ErrorText = styled.h4<{ primary?: boolean }>`
  text-align: center;
  margin-top: 15px;
  font-weight: 600;
  color: ${({ theme, primary }) => theme[primary ? 'primary' : 'red']};
`;

export const Form = styled.form`
  margin-top: 20px;

  ${OptionList} {
    max-height: 250px;
    @media (max-height: 880px) {
      max-height: 200px;
    }
    @media (max-height: 780px) {
      max-height: 175px;
    }
  }
`;

export const InputsContainer = styled.div`
  margin-bottom: 30px;
  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 30px;
`;
