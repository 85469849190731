import NewsletterIcon from '@propertypal/shared/src/resources/icons/content-hub/newsletter-icon.svg';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import { validEmail } from '@propertypal/shared/src/utils/regex';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import Button from '../buttons/Button';
import { MailBox, MailBoxInput, NewsLetterIconBox } from './ContentHubNewsLetter.style';
import ContentHubNewsLetterModal from './ContentHubNewsLetterModal';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .matches(validEmail, 'Please enter a valid email address')
    .required('Please enter your email address'),
});

interface ContentHubNewsLetterProps {
  sidebar?: boolean;
  category?: boolean;
  nav?: boolean;
  location: string;
  page: string;
  extraParams?: Record<string, any>;
  onSubmit?: () => void;
}

const ContentHubNewsLetter: React.FC<ContentHubNewsLetterProps> = ({
  sidebar,
  category,
  nav,
  location,
  page,
  extraParams,
  onSubmit,
}) => {
  const [subscribeModal, setSubscribeModal] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setSubscribeModal(values.email);

      trackGaEvent('subscribe_select', {
        page,
        location,
        ...(extraParams || {}),
      });

      if (onSubmit) onSubmit();
    },
  });

  return (
    <>
      {!!subscribeModal && (
        <ContentHubNewsLetterModal
          show={!!subscribeModal}
          onClose={() => setSubscribeModal(null)}
          email={subscribeModal}
          location={location}
          extraParams={extraParams}
        />
      )}
      <MailBox onSubmit={formik.handleSubmit} category={category} nav={nav}>
        <NewsLetterIconBox nav={nav}>
          {!sidebar && <NewsletterIcon data-testid="news-letter-icon" />}

          <div>
            <h5 style={{ fontSize: sidebar || nav ? 20 : undefined }} data-testid="news-letter-title">
              Interested in more{sidebar && ' articles like this'}?
            </h5>
            <p data-testid="news-letter-text">
              {sidebar ? 'Subscribe to our newsletter for' : 'Stay up to date with'} the latest property news and
              analysis.
            </p>
          </div>
        </NewsLetterIconBox>

        <MailBoxInput
          placeholder="Enter your email address"
          name="email"
          value={formik.values.email}
          onValueChange={formik.handleChange('email')}
          error={formik.touched.email && formik.errors.email}
          testID="news-letter-email-field"
        />

        <Button type="submit" testID="news-letter-subscribe">
          Subscribe
        </Button>
      </MailBox>
    </>
  );
};

export default ContentHubNewsLetter;
