import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.modalBg};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 15px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const Header = styled.div<{ $border: boolean }>`
  border-bottom: ${({ theme, $border }) => ($border ? `1px solid ${theme.keyline}` : 'none')};
  padding: 15px 30px;
`;

export const Content = styled.div<{ maxWidth?: number }>`
  position: relative;
  max-width: ${(props) => props.maxWidth || 1024}px;
  width: 100%;
  background-color: ${({ theme }) => theme.modalContentBg};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const Body = styled.div<{ $collapseContent?: boolean }>`
  ${({ $collapseContent }) =>
    !$collapseContent &&
    css`
      padding: 30px;

      @media (${mobileXXLargeMax}) {
        padding: 30px 15px 30px;
      }
    `}
`;
