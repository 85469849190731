import {
  laptopMax,
  mobileLargeMax,
  tabletMax,
  tabletMin,
  laptopMediumMax,
  laptopMediumMin,
} from '@propertypal/shared/src/constants/mediaQueries';
import styled, { css } from 'styled-components';
import TextInput from '../inputs/TextInput';
import { Container as InputContainer } from '../inputs/TextInput.style';

const FullWidth = css`
  display: flex;
  width: 100%;
  max-width: none;
  gap: 10px;
  align-items: center;

  ${InputContainer} {
    flex-basis: 460px;
    margin: 0;
  }

  button {
    max-width: 200px;
    height: 56px;
  }
`;

export const MailBox = styled.form<{ category?: boolean; nav?: boolean }>`
  background-color: ${({ theme }) => theme.background};
  max-width: 400px;
  width: 100%;
  padding: 20px;
  border-radius: 8px;

  h5 {
    font-size: 22px;
    color: ${({ theme }) => theme.textDark};
  }

  & > p {
    font-size: 16px;
    color: ${({ theme }) => theme.textDark};
  }

  ${InputContainer} {
    margin: 15px 0;
  }

  ${({ nav }) =>
    nav
      ? css`
          margin: 12px;
        `
      : css`
          @media (${laptopMax}) and (${tabletMin}) {
            ${FullWidth}
          }
        `}

  ${({ category, nav }) =>
    category
      ? css`
          border-radius: 8px;
          box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
          background-color: #fff;

          @media (${laptopMediumMin}) {
            ${FullWidth}
          }

          @media (${laptopMediumMax}) {
            width: 100%;
            max-width: none;
          }
        `
      : css`
          @media (${tabletMax}) {
            ${nav
              ? css`
                  width: calc(100% - 24px);
                `
              : css`
                  width: 100%;
                  max-width: none;
                `}
          }
        `}
`;

export const MailBoxInput = styled(TextInput)`
  input {
    border-radius: 6px;

    ${({ error }) =>
      error
        ? css`
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          `
        : ''}
  }
`;

export const NewsLetterIconBox = styled.div<{ nav?: boolean }>`
  display: flex;
  gap: 30px;

  & > div {
    flex: 1;
  }

  ${({ nav }) =>
    !nav
      ? css`
          @media (${mobileLargeMax}) {
            flex-direction: column;
            gap: 15px;
          }
        `
      : ''}
`;
